import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () =>
        import('./components/Home/Home.vue'),
      meta: {
        requiresAuth: true,
        title: 'TechopsAdmin'
      },
    },
    {
      path: '/userList',
      name: 'UserList',
      component: () =>
        import('./components/DatabaseAdmin/UserList.vue'),
      meta: {
        requiresAuth: true,
        title: 'TechopsAdmin'
      },
    },
    {
      path: '/permissions/:id',
      name: 'Permissions',
      component: () =>
        import('./components/DatabaseAdmin/Permissions.vue'),
      meta: {
        requiresAuth: true,
        title: 'TechopsAdmin'
      },
    },
    {
      path: '/databaseLogin',
      name: 'DatabaseLogin',
      meta: {
        requiresAuth: true,
        title: 'TechopsAdmin'
      },
      component: () =>
        import('./components/DatabaseAdmin/DatabaseLogin.vue'),
    },
    {
      path: '/PartnerPEP',
      name: 'PartnerPEP',
      component: () =>
        import('./components/PartnerPEP/PartnerPEP.vue'),
      meta: {
        requiresAuth: true,
        title: 'PartnerPEP'
      },
    },
    {
      path: '/PaCallAdmin/HubManagement',
      name: 'HubManagement',
      component: () =>
        import('./components/PaCallAdmin/HubManagement.vue'),
      meta: {
        requiresAuth: true,
        title: 'HubManagement'
      },
    },
    {
      path: '/PaCallAdmin/PaCall',
      name: 'PaCall',
      component: () =>
        import('./components/PaCallAdmin/PaCall/PaCall.vue'),
      meta: {
        requiresAuth: true,
        title: 'PaCall'
      },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () =>
        import('./components/Auth/Logout.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      component: () =>
        import('./components/Auth/Login.vue'),
      meta: {
        title: 'TechopsAdmin'
      },
    },
    {
      path: '/notConnected',
      name: 'NotConnected',
      component: () =>
        import('./components/Auth/NotConnected.vue'),
      meta: {
        guest: true,
        title: 'TechopsAdmin'
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/notConnected");
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;