import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueCookie from "vue-cookie";
import VueAxios from "vue-axios";
import VGoogleTranslate from "v-google-translate";
import Loader from "./components/global_components/loader.vue";
import BigLoader from "./components/global_components/big_loader.vue";
import MajorFailure from "./components/global_components/major_failure.vue";
Vue.component("Loader", Loader);
Vue.component("BigLoader", BigLoader);
Vue.component("MajorFailure", MajorFailure);
Vue.use(VGoogleTranslate);
Vue.use(VueCookie);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.API_URL;

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    if (error.response.status === 401) {
      store.dispatch("LogOut");
      return router.push("/notConnected");
    }
  }
  return error;
});

axios.interceptors.request.use(
  (config) => {
    let token = store.getters.AuthToken;

    if (token) {
      // TODO: Do stuff with refresh token here
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
