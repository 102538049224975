const state = {
  list_of_tags: {}
};

const getters = {

};

const actions = {
  async loadTags({ commit }, data) {
    await commit("setTags", data);
  },
  async clearTags({ commit }, action_id) {
    await commit("clearTags", action_id)
  },
  async initActionTags({ commit }, action_id) {
    await commit("initActionTags", action_id)
  },
  async ResetPEPTags({ commit }) {
    await commit("clearState");
  }
};

const mutations = {
  initActionTags(state, action_id) {
    if (state.list_of_tags[action_id] === undefined) {
      state.list_of_tags[action_id] = []
    }
  },
  setTags(state, data) {
    state.list_of_tags[data.action_id] = data.tags
  },
  clearTags(state, action_id) {
    state.list_of_tags[action_id] = []
  },
  clearState(state) {
    state.list_of_tags = {}
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
