import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import json_constructor from "./modules/json_constructor";
import list_of_tags from "./modules/list_of_tags";
import auth from "./modules/auth";
// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    auth,
    json_constructor,
    list_of_tags,
  },
  plugins: [createPersistedState()],
});
