<template>
    <div>
            <v-row>
          <v-col
          cols=4
          offset=4
          class='text-center mt-12'
          >
          <div class="header">Woops, something is not working properly.</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
          cols=6
          offset=3
          class='text-center'
          >
          <img :src="cat_error"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col
          cols=6
          offset=3
          class='text-center'
          >
          Please, contact the system administrator if the error persists.
          </v-col>
        </v-row>
        <v-row>
          <v-col
          cols=6
          offset=3
          class='text-center'
          >
          <v-alert
            dense
            type="error"
          >Error: {{error_msg}}</v-alert>
          </v-col>
        </v-row>
    </div>
</template>
<script>
import cat_error from '../../assets/ilustrations/Cat-error.png'

export default ({
    props:['error_msg'],
    data() {
        return{

            cat_error: cat_error 
        };
        
    },
})
</script>
