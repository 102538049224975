const state = {
  action_id: null,
  json_constructor_mapping: {}
};

const getters = {

};

const actions = {
  async LoadCurrentActionID({ commit }, action_id) {
    await commit("setCurrentActionId",action_id);

  },
  async AddJsonConstructor({ commit }, storeConstructorDict) {
    await commit("AddNewJsonConstructor",storeConstructorDict);

  },
  async UpdateConstructor({ commit }, left_responses) {
    await commit("UpdateJsonConstructor",left_responses);

  },
  async ResetJsonConstructor({commit}) {
    await commit("clearState");
  }
};

const mutations = {
  setCurrentActionId(state, action_id) {
    state.action_id = action_id
  },
  AddNewJsonConstructor(state, storeConstructorDict) {
    state.json_constructor_mapping[storeConstructorDict["action_id"]] = storeConstructorDict["json_constructor"]
  },
  UpdateJsonConstructor(state, left_responses) {
    state.json_constructor_mapping[state.action_id] = left_responses
  },
  clearState(state) {
    state.action_id = null
    state.json_constructor_mapping = {}
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
