import { hostname } from "@/config/variables";

const state = {
  user: null,
  authToken: null,
  tokenExpiration: null,
  scopes: [],
  default_hub_id: null,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  AgentName: (state) => state.user,
  AuthToken: (state) => state.authToken,
  TokenExpirationTime: (state) => state.tokenExpiration,
  Scopes: (state) => state.scopes,
  Default_hub_id: (state) => state.default_hub_id,
};



const mutations = {
  setUser(state, username) {
    state.user = username;
  },
  setAuthToken(state, token) {
    state.authToken = token;
  },
  setTokenExpiration(state, expiration) {
    state.tokenExpiration = expiration;
  },
  setScopes(state, scopes) {
    state.scopes = scopes;
  },
  SETDEFAULTHUBID(state, default_hub_id) {
    state.default_hub_id = default_hub_id;
  },
  logout(state, user) {
    state.user = user;
  },

};

const actions = {
  async LogIn({ commit }, user) {
    await commit("setAuthToken", user.get("token"));
    await commit("setUser", user.get("username"));
    await commit(
      "setScopes",
      user
        .get("scopes")
        .split(",")
        .map(Number)
    );
    await commit("SETDEFAULTHUBID", user.get("default_hub_id"));
    await commit("setTokenExpiration", user.get("tokenExpiration"));
  },

  async LogOut({ commit }) {
    try {
      this.axios.post(hostname + "logout");
    } catch (e) {
      console.log(e);
    }
    commit("logout", null);
    commit("setAuthToken", null);
  },
  async setDefaultHubId({ commit }, hub_id) {
    await commit("SETDEFAULTHUBID", hub_id);
  },


};

export default {
  state,
  getters,
  actions,
  mutations,
};
